<template>
  <div class="personalinfoContainerx1">
    <div class="personMessage" @click="personClick">Messages</div>

    <div v-if="!showMessage" class="messageList">
      <div class="inputStyle">
        <el-select type="text" class="inputSelect" v-model="messageType">
          <el-option value="About Academic" label="About Academic"></el-option>
          <el-option value="About Process" label="About Process"></el-option>
          <el-option value="About System Operation" label="About System Operation"></el-option>
          <el-option value="About Score" label="About Score"></el-option>
          <el-option value="About Award and Certificate" label="About Award and Certificate"></el-option>
          <el-option value="Others" label="Others"></el-option>
        </el-select>
        <el-input v-model="messageStr" class="inputStyle1"></el-input>
        <el-button class="sendStyle" @click="sendMessage">Send</el-button>
      </div>

      <div v-for="(x, index) in messageList" :key="index" class="messageDetail">
        <div style="color: #072d76; font-size: 16px">
          {{ x.type }}
        </div>
        <div style="color: #535353; font-size: 14px; margin-top: 10px">
          {{ x.message_content }}
        </div>

        <div
          v-if="x.reply_content != ''"
          style="color: #535353; font-size: 14px; margin-top: 10px"
        >
          {{ "Reply：" + x.reply_content }}
        </div>

        <div
          style="background-color: #072d76; height: 1px; margin-top: 10px"
        ></div>
      </div>
    </div>

    <!-- <div v-if="showMessage" class="studentmessageList"></div> -->
  </div>
</template>

<script>
import { sendMessage, getMyMessages } from "../../api/index";
import { getUserId, getUserType, getProjectCode } from "../../utils/store";
import "../../assets/common/font.css";

export default {
  data() {
    return {
      user: {},
      user_type: getUserType(),
      imageUrl: "",
      messageList: [],
      messageStr: "",
      showMessage: false,
      messageType: "Message Types",

      timer: "",
    };
  },
  mounted() {
    this.getMyMessages();

    // this.timer = setInterval(this.showRedMessage, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    showRedMessage() {
      getMyMessages(getUserId(), getProjectCode()).then((res) => {
        // console.log(1111,res)

        this.traversalData(res.data.data);
      });
      console.log(11);
    },
    traversalData(newData) {
      var haveNoReply = false;
      var newTip = false;

      for (var i = 0; i < newData.length; i++) {
        var message = newData[i];
        if (message.message_reply_id == "") {
          haveNoReply = true;
        }

        if (i < this.messageList.length) {
          var oldmessage = this.messageList[i];

          if (message.message_reply_id == oldmessage.message_reply_id) {
            console.log("是一致的");
          } else {
            console.log("是不一致的");
            newTip = true;
          }
        }
      }

      console.log("是否需要刷新", haveNoReply);
      console.log("是否需要提示新消息", newTip);

      if (haveNoReply == false) {
        clearInterval(this.timer);
      }

      this.messageList = newData;
    },
    clearTime() {
      clearInterval(this.timer);
    },

    getMyMessages() {
      getMyMessages(getUserId(), getProjectCode()).then((res) => {
        // console.log(1111,res)
        // this.messageList = res.data.data;

        this.messageList = []

        for (var i = 0; i < res.data.data.length; i++) {


          var messageData = res.data.data[i];


          if (messageData.type == '学术问题'){
            messageData.type = 'About Academic'
          }

          if (messageData.type == '流程问题'){
            messageData.type = 'About Process'
          }
          if (messageData.type == '数据问题'){
            messageData.type = 'About Score'
          }
          if (messageData.type == '系统操作问题'){
            messageData.type = 'About System Operation'
          }
          if (messageData.type == '奖项证书问题'){
            messageData.type = 'About Award and Certificate'
          }
          if (messageData.type == '其他问题'){
            messageData.type = 'Others'
          }
        

          this.messageList.push(messageData)

        }
      });
    },

    updateUserAvatar(url) {
      updateUserAvatar(getUserId(), url).then((res) => {
        this.getUser();
      });
    },

    sendMessage() {
      // this.timer = setInterval(this.showRedMessage, 5000);

      if (this.messageType == "Message Types") {
        this.$message.error("Message Types");
        return;
      }
      if (this.messageStr == "") {
        this.$message.error("Please type in your questions");
        return;
      }

      sendMessage(
        getUserId(),
        this.messageType,
        "student",
        this.messageStr,
        getProjectCode()
      ).then((res) => {
        this.messageStr = "";
        this.messageType = "Message Types";

        this.$message({
          message: "Send message successfully",
          type: "success",
        });

        this.getMyMessages();
      });
    },
    personClick() {
      this.showMessage = false;
    },
    studentClick() {
      this.showMessage = true;
    },
    handleEventName(event) {
      if (event) {
        let event_array = event.split(" ");
        return event_array[0];
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="less">
.personalinfoContainerx1 {
  width: 100%;
  margin: 0 auto;
  .window {
    margin-top: 1vh;
    position: relative;
    .windowImg {
      width: 100%;
      height: 60vh;
      left: 0;
      top: 0;
      z-index: -1;
      position: absolute;
    }
    .windowContent {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
  .itemLine {
    // position: absolute;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 5vh auto;
    .item {
      width: 45%;
      padding-bottom: 10px;
      border-bottom: 2px solid #072d76;
      color: #072d76;
      font-size: 2.2vh;
      font-family: Montserrat-SemiBold;
      font-weight: bold;
    }
  }
  .personMessage {
    width: 200px;
    height: 40px;
    background: #072d76;
    border-radius: 10px;
    line-height: 40px;
    color: white;
    text-align: center;
    margin-top: 30px;
    margin-left: 20px;
  }
  .messageTitle {
    color: white;
    margin-top: 30px;
    font-family: Montserrat-SemiBold;
  }
  .studentMessage {
    float: left;
    width: 40%;
    height: 60px;
    border-radius: 10px;
    background-color: blue;
    margin-top: 20px;
    font-family: Montserrat-SemiBold;
  }
  .inputStyle {
    width: 100%;
    height: 60px;
    .el-select {
      .el-input__inner {
        border: 2px solid #072d76;
      }
    }
  }
  .inputSelect {
    width: 150px;
    height: 40px;
  }
  .inputStyle1 {
    width: 50%;
    height: 40px;
    margin-left: 20px;
    margin-top: 10px;
    .el-input__inner {
      border: 2px solid #072d76;
      border-radius: 4px 0 0 4px;
    }
  }
  .el-button {
    margin-left: -1px;
    border-radius: 0 4px 4px 0;
    border: 1px solid #072d76;
    background: #072d76;
  }
  .sendStyle {
    background-color: #072d76;
    color: white;
    font-family: Montserrat-SemiBold;
  }
  .messageList {
    width: calc(100% - 40px);
    height: 100%;
    margin-left: 20px;
    background-color: #F3F5F9;
    // padding: 0 20px;
    margin-top: 30px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 10px;
  }
  .list {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .messageDetail {
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;
    font-family: Montserrat-SemiBold;
    // height: 50px;
  }
  .studentmessageList {
    width: calc(80% + 30px);
    height: calc(90% - 90px);
    background-color: #072d76;
    margin-top: 30px;
  }

  .uploadIcon {
    position: absolute;
    // width: 80px;
    // height: 20px;
    border-radius: 7.5px;
    background-color: #072d76;
    margin-top: 120px;
    align-items: center;
  }
  .uploadIconBtn {
    // position: absolute;
    border: none;
    width: 80px;
    height: 15px;
    border-radius: 7.5px;
    background-color: #072d76;
    margin-left: 0px;
    margin-top: 0px;
    // margin: 0px;
    text-align: center;
    color: white;
    font-family: Montserrat-SemiBold;
  }
  .nameItem {
    position: absolute;

    margin-left: 140px;
    margin-top: 30px;
    color: #072d76;
    font-size: 20px;
    font-family: Montserrat-SemiBold;
  }
  .accountItem {
    position: absolute;

    margin-left: 140px;
    margin-top: 60px;
    color: #072d76;
    font-size: 18px;
    font-family: Montserrat-SemiBold;
  }

  .emailItem {
    position: absolute;

    margin-left: 140px;
    margin-top: 90px;
    color: #072d76;
    font-size: 18px;
    font-family: Montserrat-SemiBold;
  }
  .lineItem {
    position: absolute;

    margin-left: 140px;
    margin-top: 130px;
    width: 300px;
    height: 1px;
    background-color: #072d76;
    font-size: 18px;
  }
  .eventItem {
    position: absolute;
    margin-left: 140px;
    margin-top: 160px;
    color: #072d76;
    font-size: 18px;
    font-family: Montserrat-SemiBold;
  }
  .eventInfo {
    margin-top: 10px;
    margin-left: 0px;
    color: #072d76;
    font-size: 13px;
    font-family: Montserrat-SemiBold;
  }
}
</style>
